<template>
    <b-container>
        <b-row>
            <b-col
                xl="4"
                offset-xl="4"
                lg="6"
                offset-lg="3"
                md="8"
                offset-md="2"
                sm="12"
                offset-sm="0"
            >

                <header :class="$style.header">

                    <div>
                        <AppName />
                    </div>

                </header>

                <form :class="$style.form" @submit.prevent="logon">
                    
                    <InputText label="Login:" type="email" v-model="login" maxlength="250" required="true" />

                    <b-row>
                        <b-col sm="7">

                            <InputText label="Senha:" type="password" v-model="password" maxlength="20" required="true" />

                        </b-col>
                        <b-col sm="5">

                            <ButtonContainer>

                                <Label />
                                <Button type="submit" label="Logar" icon="box-arrow-in-right" :loading="loading" fluid />

                            </ButtonContainer>

                        </b-col>
                    </b-row>

                </form>

                <div :class="$style.footer">

                    <a href="https://www.emix.com.br/" title="e.Mix" target="_blank">
                        <img :class="$style.footerLogo" src="@/assets/logo-emix.svg" alt="e.Mix">
                    </a>

                </div>
                    
                <AppFooter />

            </b-col>
        </b-row>
    </b-container>
</template>

<script>

import AppName from '@/components/AppHeader/AppName'
import AppFooter from '@/components/AppFooter/AppFooter'
import InputText from '@/components/Form/InputText'
import Label from '@/components/Form/Label'
import ButtonContainer from '@/components/Button/ButtonContainer'
import Button from '@/components/Button/Button'

export default {

    name: 'LoginHome',

    components: {
        AppName,
        AppFooter,
        InputText,
        Label,
        ButtonContainer,
        Button,
    },

    data() {
        return {

            loading: false,
            login: null,
            password: null

        }
    },

    mounted() {

        if(this.$store.getters.token)
            this.$router.push("/");

    },

    methods: {

        logon() {

            this.loading = true;

            const loginData = {
                login: this.login,
                password: this.password,
            }

            this.$http.post(`${this.$store.getters.api}/user/login`, loginData)
                .then((response) => {

                    if(!response.data || !response.data.success) {
                        
                        this.$store.commit('logout');
                        return;

                    }

                    this.$store.commit('login', response.data.data);

                    this.$router.push("/");

                })
                .catch(e => {
                    alert('Falha ao realizar o login!');
                    console.log(e);
                })
                .finally(() => {
                    this.loading = false;
                });

        },

    }

}

</script>

<style lang="scss" module>

.container {

    margin: 2rem 0;

}

.header {
    
    align-items: center;
    background-color: #fff;
    border-bottom: 1px solid var(--separator-color);
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    padding: 2rem;

}

.form {
    
    background-color: #fff;
    padding: 2rem 2rem 1rem 2rem;

}

.footer {
    
    align-items: center;
    background-color: #fff;
    border-top: 1px solid var(--separator-color);
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
    padding: 2rem;

}

.footerLogo {

    display: block;
    height: 30px;

}

</style>